<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('connect.topics.overview')" class="topics">
    <CCol cols="12" lg="12">
      <CCard class="mb-0">
        <CCardHeader class="pb-lg-0">
          <CRow>
            <CCol cols="5" xl="5" class="pt-0 pb-0 text-left">
              <span>{{ $t('topics.Topics') }}</span>
            </CCol>
            <CCol cols="7" xl="7" class="pt-0 pb-0 text-xl-right">
              <b-switch class="mb-0 mt-1 mt-xl-0" v-model="showInactive" size="is-small" @input="getTopics()">{{ $t('common.show_inactive') }}</b-switch>
              <div v-if="checkPermission('connect.topics.addedittopic')" class="mt-1 mt-xl-0 d-inline-block align-top">
                <CButton class="ml-2 mr-0 link" color="primary" @click="openSidebarRight('topic_details', { topic_id_external: null })">
                  <i class="fas fa-plus mr-1"/>
                  <span>{{ $t('topics.Add_topic') }}</span>
                </CButton>
              </div> 
            </CCol>             
          </CRow>
        </CCardHeader>
        <CCardBody class="pt-0 pb-0">
          <CRow class="pb-0">
            <CCol cols="12" lg="12" class="p-0">
              <b-table class="data_table" 
                       ref="topicTable" 
                       :data="topics" 
                       :striped="true" 
                       :hoverable="true" 
                       :mobile-cards="true" 
                       :paginated="isPaginated" 
                       :per-page="perPage" 
                       :current-page.sync="currentPage" 
                       :pagination-simple="isPaginationSimple" 
                       :pagination-position="paginationPosition" 
                       detailed detail-key="topic_id_external" 
                       :show-detail-icon="true">
                <template slot-scope="props">
                  <b-table-column field="title" :label="$t('common.Title')" :searchable="searchEnabled" width="20%">
                    <span class="pointer" @click="checkPermission('connect.topics.addedittopic') ? openSidebarRight('topic_details', { topic_id_external: props.row.topic_id_external }) : null">{{props.row.title}}</span>
                    <div class="item_tags d-flex flex-row">
                      <b-tag v-if="!props.row.is_active" class="mt-1 inactive">{{ $t('common.Inactive') }}</b-tag>
                    </div>
                  </b-table-column>
                  <b-table-column field="description" :label="$t('common.Description')" :searchable="searchEnabled" width="70%">
                    <span>{{props.row.description ? props.row.description : '-'}}</span>
                    <span class="d-block mt-1 meta">{{props.row.subtopics_count}} {{props.row.subtopics_count !== 1 ? $t('topics.subtopics') : $t('topics.subtopic')}}</span>
                  </b-table-column>
                  <!-- <b-table-column field="subtopics_count" :label="$t('topics.Subtopics_in_topic')" width="15%">
                    <span>{{props.row.subtopics_count}} {{props.row.subtopics_count !== 1 ? $t('topics.subtopics') : $t('topics.subtopic')}}</span>
                  </b-table-column> -->
                  <!-- <b-table-column field="updated" :label="$t('common.Last_update')" width="15%">
                    <span>{{props.row.updated | moment("YYYY-MM-DD HH:mm")}}</span>
                  </b-table-column> -->
                  <b-table-column custom-key="insights">
                    <div class="d-flex justify-content-lg-end">
                      <CDropdown v-if="checkPermission('connect.topics.topicinsights') || checkPermission('connect.topics.addedittopic')"
                                 color="primary"
                                 toggler-text=""
                                 :caret="false"
                                 class="ml-2 table_actions_dropdown">
                        <div slot="toggler-content">
                          <span class="d-flex align-items-center">
                            {{$t('common.Actions')}}<i class="fas fa-caret-down ml-1"></i>
                          </span>
                        </div>

                        <CDropdownItem v-if="checkPermission('connect.topics.topicinsights')">
                          <div class="d-flex align-items-center" @click="showTopicInsights(props.row.topic_id_external)">
                            <div class="post_action_icon text-center mr-1">
                              <i class="far fa-chart-bar"/>
                            </div>
                            <div class="flex-grow-1">
                              <span>{{$t('common.Show_insights')}}</span>
                            </div>
                          </div>
                        </CDropdownItem>
                        <CDropdownItem v-if="checkPermission('connect.topics.addedittopic')">
                          <div class="d-flex align-items-center" @click="openSidebarRight('topic_details', { topic_id_external: props.row.topic_id_external })">
                            <div class="post_action_icon text-center mr-1">
                              <i class="fas fa-pen"/>
                            </div>
                            <div class="flex-grow-1">
                              <span>{{$t('topics.Edit_topic')}}</span>
                            </div>
                          </div>
                        </CDropdownItem>
                      </CDropdown>
                    </div>
                  </b-table-column>
                </template>
                <template slot="empty">
                  <div class="p-2 text-center">
                    <loadingSpinner mode="auto" v-if="!overviewDataLoaded" :content="$t('topics.Loading_topics')"/>
                    <span v-else>{{ $t('topics.No_topics_found') }}</span>
                  </div>                
                </template>
                <template slot="detail" slot-scope="props">
                  <b-table ref="subtopicsTable" class="data_table" :data="props.row.subtopics" :striped="true" :hoverable="true" :mobile-cards="true">
                    <template slot-scope="props">                                    
                      <b-table-column field="title" :label="$t('common.Title')" :searchable="searchEnabled" width="25%">
                        <span @click="checkPermission('connect.topics.addeditsubtopic') ? openSidebarRight('subtopic_details', { subtopic_id_external: props.row.subtopic_id_external, topic_id_external: props.row.topic_id_external }) : null">{{props.row.title}}</span>
                        <div class="item_tags d-flex flex-row">
                          <b-tag v-if="!props.row.is_active" class="mt-1 inactive">{{ $t('common.Inactive') }}</b-tag>
                        </div>
                      </b-table-column>
                      <b-table-column field="description" :label="$t('common.Description')" :searchable="searchEnabled" width="70%">
                        <span>{{props.row.description ? props.row.description : '-'}}</span>
                      </b-table-column>
                      <!-- <b-table-column field="updated" :label="$t('common.Last_update')" width="15%">
                        <span>{{props.row.updated | moment("YYYY-MM-DD HH:mm")}}</span>
                      </b-table-column> -->
                      <b-table-column custom-key="edit" class="text-right" :visible="checkPermission('connect.topics.addeditsubtopic')">
                        <CButton class="m-0 d-inline-block" color="primary" @click="openSidebarRight('subtopic_details', { subtopic_id_external: props.row.subtopic_id_external, topic_id_external: props.row.topic_id_external })">
                          <i class="fas fa-pen"/>
                        </CButton>
                      </b-table-column>
                      <!-- <b-table-column custom-key="active" class="text-right">
                        <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="props.row.is_active" size="is-small" @input="updateSubtopic(props.row.subtopic_id_external, props.row);">{{ $t('is_active') }}</b-switch>
                      </b-table-column> -->
                    </template>
                    <template slot="empty">
                      <div class="p-2 text-center">
                        <span>{{ $t('topics.No_topics_found') }}</span>
                      </div>                       
                    </template>                  
                  </b-table>

                  <CButton v-if="checkPermission('connect.topics.addeditsubtopic')" class="mt-2 link" color="primary" @click="openSidebarRight('subtopic_details', { subtopic_id_external: null, topic_id_external: props.row.topic_id_external })">
                      <i class="fas fa-plus mr-1"/>
                      <span>{{ $t('topics.New_subtopic') }}</span>
                  </CButton>

                </template>
              </b-table>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>    
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue'
import noPermission from '@/components/common/noPermission.vue';

export default {
  name: 'Topics',
  components: {
    loadingSpinner,
    noPermission
  },
  data() {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,
      topics: [],
      overviewDataLoaded: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,
      searchEnabled: true,
      showInactive: false,      
    }
  },  
  methods: {
    getTopics() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/topics/overview')
      .then(res => {      
        this.topics = res.data.data;
        // Filter the data if necessary
        if(!this.showInactive) {
          this.topics = this.topics.filter( i => ['Y'].includes( i.active ) );
          // Loop through remaining topics to hide disabled subtopics
          for(var t = 0; t < this.topics.length; t++) {
            this.topics[t].subtopics = this.topics[t].subtopics.filter( i => ['Y'].includes( i.active ) );
          }
        }
        // Check if the pagination should be activated
        (this.topics.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false; 
        // Update the overviewDataLoaded value
        this.overviewDataLoaded = true;        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getTopicDetails(topicIdExternal) {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/topic/' + topicIdExternal)
      .then(res => {
        let updatedTopic = res.data.data;
        // Check if table refs are available
        if(this.$refs !== undefined && this.$refs.topicTable !== undefined ) {
          // Get the topic index
          let topicIndex = this.$refs.topicTable.data.findIndex(x => x.topic_id_external == updatedTopic.topic_id_external);
          // Update the is active value for the row
          this.$refs.topicTable.data[topicIndex].title = updatedTopic.title;
          this.$refs.topicTable.data[topicIndex].description = updatedTopic.description;
          this.$refs.topicTable.data[topicIndex].subtopics_count = updatedTopic.subtopics_count;
          this.$refs.topicTable.data[topicIndex].updated = updatedTopic.updated;
          this.$refs.topicTable.data[topicIndex].active = updatedTopic.active;
          this.$refs.topicTable.data[topicIndex].is_active = updatedTopic.is_active;

          if(!this.showInactive) {
            this.topics = this.topics.filter( i => ['Y'].includes( i.active ) );
          } 
        }    
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getSubtopicDetails(subtopicIdExternal) {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/subtopic/' + subtopicIdExternal)
      .then(res => {
        let updatedSubtopic = res.data.data;
        // Check if table refs are available
        if(this.$refs !== undefined && this.$refs.topicTable !== undefined ) {
          // Get the index of the topic of the updated subtopic
          let topicIndex = this.$refs.topicTable.data.findIndex(x => x.topic_id_external == updatedSubtopic.topic_id_external);
          // Get the index of the updated subtopic
          let subtopicIndex = this.$refs.topicTable.data[topicIndex].subtopics.findIndex(x => x.subtopic_id_external == updatedSubtopic.subtopic_id_external);
          // Update the subtopic
          this.$refs.topicTable.data[topicIndex].subtopics[subtopicIndex].title = updatedSubtopic.title;
          this.$refs.topicTable.data[topicIndex].subtopics[subtopicIndex].description = updatedSubtopic.description;
          this.$refs.topicTable.data[topicIndex].subtopics[subtopicIndex].updated = updatedSubtopic.updated;
          this.$refs.topicTable.data[topicIndex].subtopics[subtopicIndex].active = updatedSubtopic.active;
          this.$refs.topicTable.data[topicIndex].subtopics[subtopicIndex].is_active = updatedSubtopic.is_active;
          
          if(!this.showInactive) {
            this.topics[topicIndex].subtopics = this.topics[topicIndex].subtopics.filter( i => ['Y'].includes( i.active ) );
          } 
        }    
      })
      .catch(err => {
        console.error(err); 
      });
    },
    updateTopic(topicIdExternal, data) {
      let params = {}
      params = data;
      (params.is_active === true) ? params.active = 'Y' : params.active = 'N';

      axios.put(process.env.VUE_APP_API_URL + '/v1/connect/topic/' + topicIdExternal, params)
      .then(res => {
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('topics.Topic_updated'), type: 'is-success', duration: 2000 });
        // Update the topic details
        this.getTopicDetails(topicIdExternal);
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })          
    },
    updateSubtopic(subtopicIdExternal, data) {
      let params = {}
      params = data;
      (params.is_active === true) ? params.active = 'Y' : params.active = 'N';

      axios.put(process.env.VUE_APP_API_URL + '/v1/connect/subtopic/' + subtopicIdExternal, params)
      .then(res => {
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('topics.Topic_updated'), type: 'is-success', duration: 2000 });
        // Update the subtopic details
        this.getSubtopicDetails(subtopicIdExternal);                 
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })        
    },
    showTopicsInsights() {
      this.$router.push({path: '/insights/connect/topics'});
    },            
    showTopicInsights(id) {
      this.$router.push({path: `/insights/connect/topic/${id.toString()}`});
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }     
  },
  mounted: function(){
    this.getTopics();
    this.getPlatformPermissions();    

    this.$bus.$on('update_topics', () => {
      this.getTopics();
    });

    this.$bus.$on('update_topic_details', (topicIdExternal) => {
      this.getTopicDetails(topicIdExternal);
    });

    this.$bus.$on('update_subtopic_details', (subtopicIdExternal) => {
      this.getSubtopicDetails(subtopicIdExternal);
    });    
  },
  beforeDestroy() {
    this.$bus.$off('update_topics');
    this.$bus.$off('update_topic_details');
    this.$bus.$off('update_subtopic_details');
  }
}
</script>